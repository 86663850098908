<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange1"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="统计开始日期"
          end-placeholder="统计结束日期"
        />
        <el-button size="small" type="primary" icon="el-icon-search" @click="getTableData()" class="vg_mt_8">查询 </el-button>
        <el-button type="info" size="small" class="vg_mt_8" @click="buttonRefresh1()" icon="el-icon-refresh-right"> 刷新</el-button>
        <el-button type="success" size="small" class="vg_mt_8" @click="exportExcel()">导出Excel</el-button>
        <el-button type="primary" size="small" class="vg_mt_8 vg_ml_16" @click.stop="saveRow(currentRow)">保存最后一行</el-button>
      </div>
      <DynamicUTable
        id="multiTable"
        ref="multiTable"
        :tableData="tableData"
        :columns="tableProperties"
        :total-page="totalPage"
        v-loading="loadFlag1"
        :need-search="true"
        :need-check-box="false"
        :need-fixed-height="true"
        :page-size="50"
        :editActived="editActived"
        :editClosed="editClosed"
        :row-click="row => (currentRow = row)"
        @getTableData="getTableData"
      >
        <template v-slot:header-PlushAndNonePlush>
          <div style="width: 100%">
            <div style="height: 34px; border-bottom: 1px solid #e8eaec" class="flexHV">Current Stage of Production</div>
            <div class="flexHV" style="border-bottom: 1px solid #e8eaec">
              <div style="width: 50%; height: 34px" class="flexHV">Plush</div>
              <div style="width: 50%">
                <div style="margin-left: auto; height: 34px; border-left: 1px solid #e8eaec" class="flexHV">None Plush(core)</div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:PlushAndNonePlush="scope">
          <div style="display: flex">
            <el-select clearable size="small" v-model="scope.row.plush">
              <el-option v-for="(subOption, index) in options1" :key="`subSelect${subOption}${index}`" :label="subOption" :value="subOption" />
            </el-select>
            <el-select clearable size="small" v-model="scope.row.none_plush">
              <el-option v-for="(subOption, index) in options2" :key="`subSelect${subOption}${index}`" :label="subOption" :value="subOption" />
            </el-select>
          </div>
        </template>
        <template v-slot:default-PlushAndNonePlush="scope">
          <div class="flexHV">
            <div class="omitWithoutLineBreaks" style="width: 50%; margin-right: 2px">
              {{ scope.row.plush }}
            </div>
            |
            <div class="omitWithoutLineBreaks" style="width: 50%">
              {{ scope.row.none_plush }}
            </div>
          </div>
        </template>
        <template v-slot:operation="scope">
          <div class="flexHV">
            <el-button type="primary" size="mini" @click.stop="saveRow(scope.row, scope.$index)">save</el-button>
          </div>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { downloadFile, getCustomerInfo, searchTimeRangeFormatter } from '@api/public';
import { getDateNoTime } from '@assets/js/dateUtils';
import { newWeeklyPOReportProperties } from '@/views/SalesManagement/SconManage/scon';
import { sconAPI } from '@api/modules/scon';

export default {
  name: 'DataList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      activeName: 'first',
      dateRange1: [],
      tableProperties: cloneDeep(newWeeklyPOReportProperties),
      tableData: [],
      loadFlag1: true,
      totalPage: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      options1: [
        'CurrentStageofProduction',
        'Plush',
        'Packing',
        'Cutting',
        'Handsewing',
        'MachineSewing',
        'PPapproved',
        'Printing/Embroidery',
        'Lining',
        'Finished',
        'Stuffing',
        'NA'
      ],
      options2: ['CoreFinished', 'Packing', 'PPapproved', 'Vulcanizing', 'WaitingforPacking', 'Injection', 'Tooling', 'NA', 'Finished'],
      editActivedRow: {},
      currentRow: {}
    };
  },
  computed: {},
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.changeProperties();
      await this.getTableData();
    },
    async changeProperties() {
      this.tableProperties.find(({ label }) => label === 'Channel').options = await getCustomerInfo({
        status: 2,
        type: 'abbr',
        names: ['bark']
      });
    },
    getTableData() {
      this.loadFlag1 = true;
      sconAPI
        .getSconPartCountList(searchTimeRangeFormatter(this.$refs.multiTable.searchForm, [this.dateRange1], ['spdate_start_time', 'spdate_end_time']))
        .then(({ data }) => {
          this.tableData = data.list;
          this.totalPage = data.total;
          this.loadFlag1 = false;
        });
    },
    editClosed({ row }) {
      if (JSON.stringify(this.editActivedRow) !== JSON.stringify(row)) this.saveRow(row);
    },
    editActived({ row }) {
      this.editActivedRow = cloneDeep(row);
    },
    buttonRefresh1() {
      this.dateRange1 = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    exportExcel() {
      let requestBody = { type: 1, ...this.$refs.multiTable.searchForm };
      sconAPI.exportSconPartCount(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: 'NewWeeklyPoReport' + getDateNoTime(Date.now(), false) });
      });
    },
    saveRow(row, index) {
      sconAPI.editSconPartCount(row).then(({ data }) => {
        this.$message.success('保存成功!');
      });
    }
  }
};
</script>

<style scoped>
.omitWithoutLineBreaks {
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 当文本溢出时，显示省略号 */
}
</style>
